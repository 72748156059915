<template>
    <div class="components-catalog panel" v-if="components">
        <panel-legend/>
        <draggable v-model="components" class="custom-scrollbar" :options="dragOptions">
            <div  v-for="(component, index) in components"  :key="index"  :style="{order : component.order}"
                class="components-placeholder flex-container">
                <img :src="src(component.icon)"/>
                <span>{{$t(component.longname)}}</span>
            </div>
        </draggable>
    </div>
</template>
<script>
import draggable from 'vuedraggable'
import PanelLegend from '@/components/BackOffice/PanelLegend.vue'
import Templates from '@/assets/BackOfficeAssets/question-templates.json'
export default {
    name: 'ComponentsCatalog', 
    components:{
        draggable, 
        'panel-legend':PanelLegend, 
        Templates
    }, 
    computed:{ 
        components:{
            get(){
                return Templates
            }, 
            set(){
                
            }
        },
        dragOptions(){
            return{
                group:{ name:'components',  pull:'clone', put:false }, 
                ghostClass: "ghost",
            }
        }

    }, 
    methods:{
        src(icon)
        {
            switch (icon) {
                case "essay":
                    return require('@/assets/BackOfficeAssets/essay.svg')
                case "multiple-choice":
                    return require('@/assets/BackOfficeAssets/multiple-choice.svg')
                case "venn-diagram":
                    return require('@/assets/BackOfficeAssets/venn-diagram.svg')
                default:
                    return require('@/assets/BackOfficeAssets/written-answer.svg')
            }
        }
    }, 
}
</script>
