<template>
    <div class="layout-section assessment-builder row">
        <div class="layout-container" v-if="components">
            <div class="assessment-header row">
                <div class="placeholder"></div>
                <div class="placeholder"></div>
                <span class="legend secondary centered">{{$t('assessmentHeader')}}</span>
            </div>
             <draggable class="draggable-section" v-model="components" :options="dragOptions"
             :move="onMove" @change="onChange">
                <transition-group name="flip-list" tag="ul">
                    <li v-for="component in components" :key="component.id" >
                        <layout-component :component="component"/>  
                    </li>
                </transition-group>
            </draggable>
        </div>
        <questions-catalog />
    </div>
</template>
<script>
import QuestionsCatalog from '@/components/BackOffice/LayoutComponents/QuestionsCatalog.vue'
import AssessmentBuilderLayoutComponent from './AssessmentBuilderLayoutComponent.vue'
import LayoutSectionMixin from '@/components/BackOffice/Mixins/LayoutSectionMixin.js'
import Factory from '@/utils/ContentFactory.js'
export default {
    name: 'AssessmentBuilderLayoutSection', 
    mixins:[LayoutSectionMixin],
    components:{
        QuestionsCatalog, 
        'layout-component': AssessmentBuilderLayoutComponent, 
    },
    methods:{        
        onMove({relatedContext, draggedContext}) {
            const relatedElement = relatedContext.element;
            const draggedElement = draggedContext.element;

            return relatedElement && relatedElement.type === draggedElement.type;
        }, 
        onChange({added, moved}){
            if(added)
               this.createComponent(added)
            if(moved)
               this.moveComponent(moved)
        },
        createComponent(added){
            if(added.element.type === 'essay' && this.hasAssessmentQuestionOfTypeEssay)
            {
                this.$store.commit('addSnackBarNotification',{
                    message: this.$t('multipleEssaysInAssessmentAlert')
                })
                return
            }
            if(added.element.type === 'venn-diagram' && this.hasAssessmentQuestionOfTypeVennDiagram)
            {
                this.$store.commit('addSnackBarNotification',{
                    message: this.$t('multipleEssaysInAssessmentAlert')
                })
                return
            }            
            let newQuestion = {
                order : this.getComponentPartialOrder(added.element.type, added.newIndex + 1),
                ...added.element,
                question: '',
                audio: {},
                assessmentId: this.$store.state.assessmentHeader.id,
                imageId: '',
                primarySetContent : new Factory.CreateTextContent(),
                primarySetImage :{ id:null},
                secondarySetContent :new Factory.CreateTextContent(),
                secondarySetImage :{ id:null},
                vennDiagramElements: [], 
                teks:[]
            }

            if(newQuestion.type != "written"){
                newQuestion.choices = [];
            }
            this.$store.dispatch('addQuestionToAssessment', newQuestion)
                        .then(()=>{
                            this.$store.commit("sortAssessmentBuilderComponents");
                        });
        }, 
        moveComponent(moved){
            let component = this.components.find(e => e.id === moved.element.id)
            let partialOrder = this.getComponentPartialOrder(component.type, moved.newIndex + 1);
            
            this.$store.dispatch('updateQuestionComponentOrder', { ...component, order: partialOrder });
        },
        getComponentPartialOrder(questionType, overallOrder){
            let firstComponent = this.components.find(elem => elem.type == questionType);

            return firstComponent && firstComponent.order < overallOrder ?
                        overallOrder - firstComponent.order + 1 : 1;
        }
    }, 
    computed:{
        components:{
            get()
            {
                return this.$store.state.assessmentQuestions
            },
            set(value){
                let components = value.filter(e => e.id)
                this.$store.commit('updateQuestionComponentOrder', components)
            }
        }, 
        hasAssessmentQuestionOfTypeEssay(){
            return this.components.find(e => e.type === 'essay')
                ? true : false
        },
        hasAssessmentQuestionOfTypeVennDiagram(){
            return this.components.find(e => e.type === 'venn-diagram')
                ? true : false
        }
    }
}
</script>
<style lang="less" scoped>

.ghost{
    box-sizing: border-box;
    height: 1px;
    border: 4px solid #3A4DFD;
    margin: 0 auto;
}


.layout-container, .layout-container.draggable-section, ul{
    width: 100%;
    min-height: 300px;
}

.layout-container{
    margin-left: 35px;
}

.layout-section{
    .assessment-header{
        height: 152px;
        width: 704px;
        background-color: #EFEFEF;
        box-shadow: var(--primary-shadow);
        position: relative;
        margin: 16px auto;

        .placeholder{
            height: 136px;
            width: 207px;
            background-color: #D8D8D8;
            margin-top: 8px;
            margin-left: 17px;
        }    
        .placeholder:nth-child(2){
            height: 40px;
            width: 446px;
            background-color: #D8D8D8;
        }
        .legend{
            top:50%;
            transform: translate(-50%, -50%);
            margin-top: 0;
        }
    }
}

</style>