<template>
    <div class="content-component column" 
        :class="[{'component-active': isActive}, component.type]"
        @click="updateCurrentComponent" >
        <div class="row">
            <label >{{$t('questionTag')}}:</label>
             <ckeditor :editor="editor" :placeholder="$t('questionPlaceHolder')"
                v-model="question" :config="editorConfig"></ckeditor>
        </div>
        <div class="column venn-diagram-sets">
            <venn-diagram-set :set="'primarySet'" :component="component"/>
            <venn-diagram-set :set="'secondarySet'" :component="component"/>
        </div>
        <venn-diagram-element v-for="(element, index) in elements" :key="'venn-diagram-element'+element.id" :index="index +1"
        :question="component" :component="element"/>
        <span class="legend centered">{{$t('vennDiagramLegend')}}</span>
        <button class="btn secondary delete" @click="deleteComponent" >{{$t('delete')}}</button>
        <button class="btn secondary new-element" @click="addElement" >{{$t('addElementTag')}}</button>
    </div>
</template>
<script>
import BackOfficeComponentMixin from '@/components/BackOffice/Mixins/BackOfficeComponentMixin.js'
import VennDiagramElement from './VennDiagramElement.vue'
import VennDiagramSet from './VennDiagramSet.vue'
export default {
    name: 'VennDiagramComponent', 
    mixins:[BackOfficeComponentMixin], 
    components:{
        VennDiagramElement, 
        VennDiagramSet
    },
    computed:{
        question:{
            get(){
                return this.component.question[this.locale]
            }, 
            set(value)
            {
                this.setComponentPropertyValue(value, 'question', this.locale)
            }
        },
        elements(){
            return this.component.vennDiagramElements
        },
    }, 
    methods:{
        addElement(){
            this.$store.dispatch('addVennDiagramElement', this.component)
        }
    }
}
</script>



