<template>
    <div class="content-component column" 
        :class="[{'component-active': isActive}, component.type]"
        @click="updateCurrentComponent" >
        <div class="row">
            <label >{{$t('questionTag')}}:</label>
             <ckeditor :editor="editor" :placeholder="$t('questionPlaceHolder')"
                v-model="question" :config="editorConfig"></ckeditor>
        </div>
        <question-choice v-for="(choice, index) in choices" :key="index" :index="index +1"
        :question="component" :component="choice" v-on:correctChange="updateCorrectChoice" />
        <span class="legend centered">{{$t('multipleChoiceLegend')}}</span>
        <button class="btn secondary delete" @click="deleteComponent" >{{$t('delete')}}</button>
        <button class="btn secondary new-choice" @click="addChoice" >{{$t('addChoiceTag')}}</button>
    </div>
</template>
<script>
import BackOfficeComponentMixin from '@/components/BackOffice/Mixins/BackOfficeComponentMixin.js'
import QuestionChoice from './QuestionChoice.vue'
export default {
    name: 'MultipleChoiceComponent', 
    mixins:[BackOfficeComponentMixin], 
    components:{
        QuestionChoice
    },
    computed:{
        question:{
            get(){
                return this.component.question[this.locale]
            }, 
            set(value)
            {
                this.setComponentPropertyValue(value, 'question', this.locale)
            }
        },
        choices(){
            return this.component.choices
        }
    }, 
    methods:{
        addChoice(){
            this.$store.dispatch('addChoiceToQuestion', this.component)
        }, 
        updateCorrectChoice(choice){
            this.$store.dispatch('updateCorrectAnswer',{
                question: this.component, 
                choice: choice
            })
        }
    }
}
</script>



