<template>
    <div class="content-component column" 
        :class="[{'component-active': isActive}, component.type]"
         @click="updateCurrentComponent" >
        <div class="row">
            <label for="question">{{$t('questionTag')}}:</label>
              <ckeditor :editor="editor" :placeholder="$t('questionPlaceHolder')"
                v-model="question" :config="editorConfig"></ckeditor>
        </div>
        <span class="legend centered">{{$t('writtenLegend')}}</span>
        <button class="btn secondary delete" @click="deleteComponent">{{$t('delete')}}</button>
    </div>
</template>

<script>
import BackOfficeComponentMixin from '@/components/BackOffice/Mixins/BackOfficeComponentMixin.js';

export default{
    name: 'WrittenAnswerComponent',
    mixins: [BackOfficeComponentMixin],
    computed: {
        question: {
            get(){
                return this.component.question[this.locale];
            },
            set(value){
                this.setComponentPropertyValue(value, "question", this.locale);
            }
        }
    }
}
</script>
