import { render, staticRenderFns } from "./AssessmentBuilderLayoutSection.vue?vue&type=template&id=af403894&scoped=true&"
import script from "./AssessmentBuilderLayoutSection.vue?vue&type=script&lang=js&"
export * from "./AssessmentBuilderLayoutSection.vue?vue&type=script&lang=js&"
import style0 from "./AssessmentBuilderLayoutSection.vue?vue&type=style&index=0&id=af403894&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af403894",
  null
  
)

component.options.__file = "AssessmentBuilderLayoutSection.vue"
export default component.exports