<template>
    <div class="content-component essay-component column" 
        :class="[component.type, {'component-active': isActive}]"
         @click="updateCurrentComponent" >
        <div class="row">
            <label for="text">{{$t('questionTag')}}:</label>
            <ckeditor :editor="editor" :placeholder="$t('questionPlaceHolder')"
            v-model="directions" :config="editorConfig"></ckeditor>
        </div>
        <span class="legend centered">{{$t('essay')}}</span>
        <button class="btn secondary delete" @click="deleteComponent" >{{$t('delete')}}</button>
    </div>
</template>
<script>
import BackOfficeComponentMixin from '@/components/BackOffice/Mixins/BackOfficeComponentMixin.js'
export default {
    name: 'EssayComponent', 
    mixins:[BackOfficeComponentMixin], 
    computed:{
        tips:{
            get(){
                return this.component.tips[this.locale]
            }, 
            set(value)
            {
                this.setComponentPropertyValue(value, 'tips', this.locale)
            }
        },
        directions:{
            get(){
                return this.component.question[this.locale]
            }, 
            set(value)
            {
                this.setComponentPropertyValue(value, 'question', this.locale)
            }
        }, 
        components(){
            return this.$store.state.assessmentQuestions
        }
    }
}
</script>